import React, { FC, MouseEventHandler } from 'react'
import { navigate, PageProps } from 'gatsby'
import { Button, ButtonGroup } from '@mui/material'
import {
  DataGridProProps,
  GridActionsCellItem,
  GridLinkOperator,
  GridRowParams,
  GridValueGetterParams
} from '@mui/x-data-grid-pro'
import {
  deleteAllResults,
  deleteResults,
  getHosts,
  TGetResultsFilterProps
} from '@utils/api'
import {
  alertCountRenderer,
  chipRenderer,
  errorCountRenderer,
  hostOsRenderer,
  hostStatusRenderer,
  quickColumn,
  tooltipRenderer,
  transformApiResponseToRows
} from '@utils/tables'
import {
  Delete,
  Dns,
  TrackChanges,
  Refresh,
  Search,
  WarningRounded
} from '@mui/icons-material'
import { Notice, WithCaution } from '@components'
import GenericTablePage from '@templates/Generic/Table'
import useApi from '@hooks/useApi'
import { resultsHostPresets } from '@utils/results'
import useLicense from '@hooks/useLicense'
import { upgradeNotice } from '@components/Notice/presets'
import { isHostOffline, isHostUnknown } from '@utils/hosts'

const columns: DataGridProProps['columns'] = [
  quickColumn({ field: 'id' }),
  {
    field: 'host_id',
    headerName: 'Host Id',
    width: 120,
    hide: true,
    type: 'enhancedString'
  },
  {
    field: 'status_icon',
    headerName: 'Status',
    width: 100,
    align: 'left',
    headerAlign: 'left',
    type: 'actions',
    getActions: hostStatusRenderer
  },
  {
    field: 'hostname',
    headerName: 'Target Address',
    width: 150,
    type: 'enhancedString'
  },
  {
    field: 'os_info_node',
    headerName: 'Hostname',
    width: 150,
    type: 'enhancedString',
    renderCell: tooltipRenderer
  },
  {
    field: 'results_alert',
    headerName: 'Alerts',
    width: 130,
    type: 'number',
    renderCell: alertCountRenderer,
    cellClassName: 'DataGrid__cell--show-button-on-hover'
  },
  {
    field: 'results_total',
    headerName: 'Total Results',
    width: 100,
    type: 'number',
    hide: true
  },
  {
    field: 'results_error',
    headerName: 'Errors',
    width: 130,
    type: 'number',
    cellClassName: 'DataGrid__cell--show-button-on-hover',
    renderCell: errorCountRenderer
  },
  {
    field: 'results_pass',
    headerName: 'Passes',
    width: 100,
    type: 'number'
  },

  {
    field: 'os_info_os_release_pretty_name',
    headerName: 'OS',
    width: 200,
    renderCell: hostOsRenderer,
    type: 'enhancedString',
    hide: true
  },

  {
    field: 'active',
    headerName: 'Active',
    type: 'boolean',
    width: 120,
    hide: true
  },
  {
    field: 'tags',
    headerName: 'Tags',
    width: 170,
    renderCell: chipRenderer,
    sortable: false,
    type: 'enhancedString',
    hide: true
  },
  {
    field: 'jump_hosts',
    headerName: 'Jump Hosts',
    width: 170,
    renderCell: chipRenderer,
    sortable: false,
    type: 'enhancedString',
    hide: true
  },

  {
    field: 'credentials_id',
    headerName: 'Credential',
    width: 120,
    type: 'enhancedString',
    hide: true
  },
  {
    field: 'date_last_seen',
    headerName: 'Last Seen',
    type: 'dateTime',
    minWidth: 180,
    flex: 1,
    cellClassName: 'DataGrid__cell--last-seen'
  },
  {
    field: 'isOffline',
    headerName: 'Is Offline',
    type: 'boolean',
    hide: true,
    valueGetter: (params: GridValueGetterParams) => {
      return isHostOffline(params.row)
    }
  },

  {
    field: 'isUnknown',
    headerName: 'Is Unknown',
    type: 'boolean',
    hide: true,
    valueGetter: (params: GridValueGetterParams) => {
      return isHostUnknown(params.row)
    }
  }
]

type ResultsPageProps = PageProps<
  object,
  object,
  { requestParams?: TGetResultsFilterProps }
>

const ResultsPage: FC<ResultsPageProps> = () => {
  const api = useApi({ apiMethod: getHosts })
  const { isTrial } = useLicense()

  const deleteApi = useApi<any>({
    apiMethod: deleteResults,
    requestOnMount: false
  })

  const deleteAllApi = useApi<any>({
    apiMethod: deleteAllResults,
    requestOnMount: false
  })

  const { loading, error, response, enqueueSnackbar } = api

  const rowClassName = ({ row }: GridRowParams): string => {
    const classes = []

    const { results_alert, results_error, results_pass, results_total } = row

    const isOffline = row['isOffline']
    const isUnknown = row['isUnknown']

    results_alert > 0 && classes.push('DataGrid__row--has-alerts')

    results_error > 0 && classes.push('DataGrid__row--has-errors')

    results_pass > 0 && classes.push('DataGrid__row--has-passes')

    results_total === 0 && classes.push('DataGrid__row--no-results')

    isOffline && classes.push('DataGrid__row--is-stale')

    isUnknown && classes.push('DataGrid__row--is-unknown')

    return classes.join(' ')
  }

  const rowClickHandler = (params: GridRowParams, smartFilter: boolean = false) => {
    const { host_id } = params.row
    const { results_error, results_alert, results_pass } = params.row

    if (results_error + results_alert + results_pass === 0) {
      return false
    }

    const hasAlerts = results_alert > 0
    const hasErrors = results_error > 0
    const items = [
      {
        columnField: 'header.host_id',
        operatorValue: 'equals',
        value: host_id,
        id: 1
      }
    ]
    
    if (smartFilter && (hasAlerts || hasErrors)) {
      items.push({
        columnField: 'data.status',
        operatorValue: 'not',
        value: 'pass',
        id: 2
      })
    }

      navigate(`/results/all/`, {
        state: {
          requestParams: {
            filter: {
              items,
              linkOperator: 'and' as GridLinkOperator
            }
          }
        }
      })
  }

  const handleAllDelete = () => {
    deleteAllApi.makeRequest().then(() => {
      enqueueSnackbar('All Results Deleted.', {
        variant: 'success'
      })

      setTimeout(() => api.makeRequest(), 1000)
    })
  }

  const selectionLogicHandler = ({ row }: GridRowParams) => {
    return !row['isUnknown']
  }

  const handleRefresh: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault()
    api.refresh()
  }

  const rows =
    transformApiResponseToRows({
      response
    }) || []

  return (
    <GenericTablePage
      title="Results by Host"
      api={api}
      notice={isTrial ? <Notice {...upgradeNotice} /> : null}
      breadcrumbs={[{ title: 'Results' }]}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Results Actions"
        >
          <Button
            href="#"
            role="button"
            endIcon={<Refresh />}
            onClick={handleRefresh}
          >
            Refresh
          </Button>

          <WithCaution message="You are about to delete all results. This action is permanent. Are you sure you wish to proceed?">
            <Button
              href="#"
              role="button"
              onClick={() => handleAllDelete()}
              endIcon={<WarningRounded />}
            >
              Delete All Results
            </Button>
          </WithCaution>
        </ButtonGroup>
      }
      gridOptions={{
        loading: loading,
        error: error || null,
        rowHeight: 64,
        columns: [
          ...columns,
          {
            field: 'actions',
            headerName: 'Actions',
            headerAlign: 'right',
            type: 'actions',
            align: 'right',
            width: 120,
            getActions: (params: GridRowParams) => {
              const { results_error, results_alert, results_pass } = params.row

              const hasResults =
                results_error + results_alert + results_pass > 0
              return [
                <GridActionsCellItem
                  icon={<Search />}
                  disabled={!hasResults}
                  label={'View Results'}
                  onClick={() => rowClickHandler(params)}
                />,
                <GridActionsCellItem
                  icon={<Dns />}
                  label={'View Host'}
                  onClick={() => navigate(`/hosts/host/${params.id}/`)}
                />,
                <GridActionsCellItem
                  icon={<TrackChanges />}
                  label={'Re-scan Host'}
                  onClick={() =>
                    navigate(`/scan/`, {
                      state: {
                        host_ids: [params.id]
                      }
                    })
                  }
                />
              ]
            }
          }
        ],
        rows,
        rowCount: response?.total || 0,
        disableSelectionOnClick: true,
        disableColumnMenu: true,
        checkboxSelection: true,
        // onFilterModelChange: onFilterChange,

        initialSort: [
          {
            field: 'results_alert',
            sort: 'desc'
          },
          {
            field: 'results_error',
            sort: 'desc'
          },
          {
            field: 'hostname',
            sort: 'desc'
          }
        ],
        // onSortModelChange: handleSortModelChange,
        onRowClick: (params: GridRowParams) => rowClickHandler(params, true),
        isRowSelectable: selectionLogicHandler,
        total: response?.total || 0,
        getRowClassName: rowClassName
      }}
      presets={resultsHostPresets}
      bulkActions={[
        {
          text: 'Purge Selected Hosts',
          icon: <Delete fontSize="small" />,
          action: async (selection: string[]) => {
            await deleteApi
              .makeRequest({
                items: selection.map((id, index) => ({
                  columnField: 'header.host_id',
                  operatorValue: 'equals',
                  value: id,
                  id: index
                })),
                linkOperator: 'or' as GridLinkOperator
              })
              .then(() => {
                enqueueSnackbar('Delete in progress. This may take a moment.', {
                  variant: 'success'
                })
                setTimeout(() => api.makeRequest(), 1000)
              })
          },
          autoRefresh: false
        },
        {
          text: 'Scan Selected Hosts',
          icon: <TrackChanges fontSize="small" />,
          action: async (selection: string[]) =>
            await navigate(`/scan/`, {
              state: {
                host_ids: selection
              }
            }),

          autoRefresh: false
        },
        {
          text: 'View Results',
          icon: <Search fontSize="small" />,
          action: async (selection: string[]) =>
            await navigate(`/results/all/`, {
              state: {
                requestParams: {
                  filter: {
                    items: selection.map((id, index) => ({
                      columnField: 'header.host_id',
                      operatorValue: 'equals',
                      value: id,
                      id: index
                    })),
                    linkOperator: 'or' as GridLinkOperator
                  }
                }
              }
            }),

          autoRefresh: false
        }
      ]}
    ></GenericTablePage>
  )
}

export default ResultsPage
